import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { AuthContext } from "../../components/Auth/AuthDataProvider";

import {
  Box,
  List,
  ListItem,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';

// import Icon from '@material-ui/core/Icon';
import logoTextWhite from '../../assets/img/newlogo.png';

import { ReactComponent as ArrowRightIcon } from '../../assets/img/left_menu/arrow-right.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import { ReactComponent as CustomersIcon } from '../../assets/img/left_menu/customers.svg';
import { ReactComponent as ReportIcon } from '../../assets/img/left_menu/report.svg';
import { ReactComponent as ResourcesIcon } from '../../assets/img/left_menu/resources.svg';
import { ReactComponent as LiveTrackingIcon } from '../../assets/img/left_menu/live-tracking.svg';

import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import WMSIcon from '../../assets/img/services-icon1.png';
import LDSIcon from '../../assets/img/services-icon2.png';
import apiUtil from '../../api/apiUtil';

const CustomMenuItem = (props) => {
  let { text, icon, url, active, items, name } = props;

  let [open, setOpen] = React.useState(active);

  if (apiUtil.checkAccess(name)) {
    return <React.Fragment>
      <ListItem
        className={'custom-item ' + ((typeof active === "function") ? active() : (active ? "active" : ""))}
      >
        <a href={(items && items.length > 0) ? '' : url}
          onClick={(e) => {
            if (items && items.length > 0) {
              e.preventDefault();
              e.stopPropagation();

              setOpen(!open);
            }
          }}
        >
          {icon ? icon : <span className={'no-icon'}></span>}
          <span>{text}</span>
          {(items && items.length > 0) && (open ? <ArrowDownIcon className={'custom-expand-down'} /> : <ArrowRightIcon className={'custom-expand-right'} />)}
        </a>
      </ListItem>
      {(items && items.length > 0) && <Collapse in={open} timeout="auto" unmountOnExit>
        <List className={'sub-nav'} component="div" disablePadding>
          {items.map((item, i) => {
            return <React.Fragment key={i}>
              {item}
            </React.Fragment>;
          })}
        </List>
      </Collapse>}
    </React.Fragment>
  }

  return null;
}

const ServiceMenuSelector = (props) => {
  let { handleChange } = props;
  
  if (apiUtil.checkAccess("ServicesMenuSelector")) {
    return <div id="servicesMenuSelector">
      <label>Services</label>
      <Select
        value='#'
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        onChange={(e) => handleChange(e)}
      >
        <MenuItem style={{ width: 210 }} className="dark-select" value={'#'}><img src={WMSIcon} className="service-icon" alt="WMS Icon" />Warehouse Management System</MenuItem>
        <MenuItem style={{ width: 210 }} className="dark-select" value={'https://wls.digitalrocketship.net/'}><img src={LDSIcon} className="service-icon" alt="LDS Icon" />Workflow Logistic System</MenuItem>
      </Select>
    </div>
  }

  return null;
}


class LeftMenu extends Component {
  static contextType = AuthContext;

  validURL = (str) => {
    let pattern = new RegExp('^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$', 'i');
    return !!pattern.test(str);
  }

  handleServiceChange = (e) => {
    let url = e.target.value;
    if (!this.validURL(url)) return false;
    window.location.replace(url);
  }

  render() {
    let scope = localStorage.getItem("scope");

    return <Box>
      <List component="nav">
        <div className={this.props.className + ' custom-logo'}>
          <a href={'/'}><img src={logoTextWhite} alt={'WorkNode'} width={'100%'} /></a>
        </div>

        <ServiceMenuSelector handleChange={this.handleServiceChange} />

        <hr />

        <CustomMenuItem
          text={'Customer'}
          name={'Customer'}
          icon={<CustomersIcon />}
          url={'/customers/all-customers'}
          active={this.props.location.pathname.includes("/customer")}
        />
        
        {/* <CustomMenuItem
          text={'Vehicle'}
          name={'Vehicle'}
          icon={<LiveTrackingIcon />}
          url={'/vehicles/all-vehicles'}
          active={this.props.location.pathname.includes("/vehicle")}
        /> */}
        
        <CustomMenuItem
          text={'Vehicle Checklist'}
          name={'VehicleChecklistCreate'}
          icon={<LiveTrackingIcon />}
          url={'/vehicles/create-vehicle-checklist'}
          active={this.props.location.pathname.includes("/vehicles/create-vehicle-checklist")}
        />
        
        <CustomMenuItem
          text={'Forklift Checklist'}
          name={'ForkliftChecklistCreate'}
          icon={<LiveTrackingIcon />}
          url={'/vehicles/create-forklift-checklist'}
          active={this.props.location.pathname.includes("/vehicles/create-forklift-checklist")}
        />

        <CustomMenuItem
          text={'Transactions'}
          name={'Transactions'}
          icon={<ResourcesIcon />}
          active={this.props.location.pathname.includes("/transaction")}
          items={[
            <CustomMenuItem
              text={'Sales Order'}
              name={'SalesOrder'}
              url={'/transaction/sales-order/all'}
              active={this.props.location.pathname === "/transaction/sales-order"}
            />,
            <CustomMenuItem
              text={'Picking Order'}
              name={'PickingOrder'}
              url={'/transaction/picking-order'}
              active={this.props.location.pathname === "/transaction/picking-order"}
            />,
            <CustomMenuItem
              text={'Delivery Order'}
              name={'DeliveryOrder'}
              url={'/transaction/delivery-order'}
              active={this.props.location.pathname === "/transaction/delivery-order"}
            />,

            // <CustomMenuItem
            //   text={'Check Delivery Order Out'}
            //   url={'/transaction/delivery-order/check'}
            //   active={this.props.location.pathname === "/transaction/delivery-order/check"}
            // />,
            // <CustomMenuItem
            //   text={'Self Collection'}
            //   url={'/transaction/self-collection'}
            //   active={this.props.location.pathname === "/transaction/self-collection"}
            // />,
            <CustomMenuItem
              text={'Balance Enquiry'}
              name={'BalanceEnquiry'}
              url={'/transaction/balance-enquiry/list'}
              active={this.props.location.pathname === "/transaction/balance-enquiry/list"}
            />,
            <CustomMenuItem
              text={'Balance Enquiry All'}
              name={'BalanceEnquiryAll'}
              url={'/transaction/balance-enquiry/all'}
              active={this.props.location.pathname === "/transaction/balance-enquiry/all"}
            />
          ]}
        />

        <CustomMenuItem
          text={'Report'}
          name={'Report'}
          icon={<ReportIcon />}
          active={this.props.location.pathname.includes("/report") || this.props.location.pathname.includes("/vehicle")}
          items={[
            <CustomMenuItem
              text={'Rental/Handling Report'}
              name={'Rental/HandlingReport'}
              url={'/report/in-out'}
              active={this.props.location.pathname === "/report/in-out"}
            />,
            <CustomMenuItem
              text={'Monthly Summary Report'}
              name={'MonthlySummaryReport'}
              url={'/report/monthly-summary'}
              active={this.props.location.pathname === "/report/monthly-summary"}
            />,
            <CustomMenuItem
              text={'Aging Report'}
              name={'AgingReport'}
              url={'/report/aging'}
              active={this.props.location.pathname === "/report/aging"}
            />,
             <CustomMenuItem
                text={'Vehicle'}
                name={'Vehicle'}
                url={'/vehicles/all-vehicles'}
                active={this.props.location.pathname.includes("/vehicle")}
              />
          ]}
        />
      </List>
    </Box>
  }
}

export default withRouter(LeftMenu);
