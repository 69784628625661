import React, { Component } from 'react';
import moment from 'moment';

import apiUtil from '../../api/apiUtil';

import { 
    Box,
} from '@material-ui/core';

import { DateRangePicker } from "materialui-daterange-picker";

import CustomDropdown from '../Dropdown/CustomDropdown';
import CustomInput from './CustomInput';

import { ReactComponent as CalendarIcon } from '../../assets/img/icons/calendar.svg';

class CustomDateRangePicker extends Component {
  
	constructor(props){
		super(props);

        this.refInput = React.createRef();
        
        let className = props.className ? props.className : "";
        let label = props.label ? props.label : null;
        let open = ((props.open === true || props.open === false) ? props.open : false);
        let disablePortal = ((props.disablePortal === true || props.disablePortal === false) ? props.disablePortal : true);
        let range = (props.range) ? props.range : null;

		this.state = {
            range: range,
            placeholder: 'MMM DD, YYYY - MMM DD, YYYY',
            label: label,
            open: open,
            disablePortal: disablePortal,
            className: className,
		}
	}
    

    componentWillReceiveProps(nextProps) {
		if (nextProps.open !== this.state.open) {
            let open = ((nextProps.open === true || nextProps.open === false) ? nextProps.open : false);
			this.setState({
				open: open
			});
        }
		if (nextProps.disablePortal !== this.state.disablePortal) {
            let disablePortal = ((nextProps.disablePortal === true || nextProps.disablePortal === false) ? nextProps.disablePortal : true);
			this.setState({
				disablePortal: disablePortal
			});
        }
		if (nextProps.range !== this.state.range) {
            let range = (nextProps.range) ? nextProps.range : null;
			this.setState({
				range: range
			});
        }
    }


    toggle = () => {
        this.setState({
            open: !this.state.open,
        });
    };

    formatDates = () => {
        let range = this.state.range;

        let startDate = (range && range.startDate) ? range.startDate : null;
        let endDate = (range && range.endDate) ? range.endDate : null;

        if(startDate && endDate){
            return moment(startDate).format(apiUtil.getDefaultDateFormat()) + ' - ' + moment(endDate).format(apiUtil.getDefaultDateFormat());
        } else {
            return '';
        }
    };


	render() {
		return <Box 
            className={"custom-date-range-picker-component " + this.state.className}>
            <CustomDropdown
                open={this.state.open}
                disablePortal={this.state.disablePortal}
                onChange={(state) => {
                    this.setState({
                        open: !state,
                    });
                }}
                element={<CustomInput
                    className={'no-margin'}
                    label={this.state.label}
                    placeholder={this.state.placeholder}
                    color={'secondary'}
                    readOnly={true}
                    endAdornment={(obj) => {
                        return <Box>
                            <CalendarIcon />
                        </Box>
                    }}
                    onClick={() => {
                        this.setState({
                            open: true,
                        });
                    }}
                    value={this.formatDates()}
                />}
            >
                <Box position={'relative'}>
                    <DateRangePicker
                        wrapperClassName={'custom-date-range-picker'}
                        open={true}
                        toggle={true}
                        onChange={(range) => {
                            this.setState({
                                open: false,
                                range: range,
                            }, () => {
                                if(this.props.onChange){
                                    this.props.onChange(range);
                                }
                            });
                        }}
                        initialDateRange={{
                            startDate: this.state.startDate,
                            endDate: this.state.endDate,
                        }}
                    />
                </Box>
            </CustomDropdown>
            
		</Box>;
	}
}

export default CustomDateRangePicker;
