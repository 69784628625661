import axios from 'axios';

const {
  REACT_APP_WMS_API_URL
} = process.env;

const deliveryOrderApi = {
  baseApiUrl: () => {
    return REACT_APP_WMS_API_URL;
  },

  get: async (params) => {
    let errorMessage = "";
    let total = 0;

    params.handling_in = false;

    if (params.optional_filter_by && params.optional_filter_by != "query") {
      params[params.optional_filter_by] = params.optional_query;
      params.optional_filter_by = null;
      params.optional_query = null;
    }

    const URL = REACT_APP_WMS_API_URL + "admin/api/orders";

    let access_token = localStorage.getItem("access_token");
    let result = [];

    await axios.get(
      URL,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
      .then(res => {
        if (res.status === 200 && res.data.result.length > 0) {
          total = res.data.total;
          for (let i = 0; i < res.data.result.length; i++) {
            result[i] = res.data.result[i];
            if (res.data.result[i].end_user) {
              result[i]['end_user_name'] = res.data.result[i].end_user.end_user;
            }
          }
        }
      }).catch(error => {
        errorMessage = error.message;
      });

    return { data: result, total: total, errorMessage: errorMessage };
  },

  show: async (id) => {
    let errorMessage = "";
    let status = 200;

    const URL = REACT_APP_WMS_API_URL + "admin/api/orders/" + id;

    let access_token = localStorage.getItem("access_token");

    const getResult = await axios.get(
      URL,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
      .catch(error => {
        status = error.response.status;
        errorMessage = error.message;
      });

    return { data: getResult.data, errorMessage: errorMessage, status: status };
  },

  create: async (request) => {
    let errorMessage = "";
    let status = 200;
    let URL = REACT_APP_WMS_API_URL + "admin/api/delivery_orders";
    if (request.orderType == "PickingOrder") {
      URL = REACT_APP_WMS_API_URL + "admin/api/picking_orders";
    }
    let access_token = localStorage.getItem("access_token");

    const result = await axios.post(
      URL,
      request,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
      .catch(error => {
        status = error.response.status;
        errorMessage = error.message;
      });
    return { data: result, errorMessage: errorMessage, status: status };
  },

  convertDO: async (id) => {
    let errorMessage = "";
    let status = 200;
    let URL = REACT_APP_WMS_API_URL + "admin/api/convert_po_to_do/" + id;

    let access_token = localStorage.getItem("access_token");

    const result = await axios.post(
      URL,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
      .catch(error => {
        status = error.response.status;

        if (error.response && error.response.data && error.response.data.error) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = error.message;
        }
      });

    //console.log("apires", result);

    return { data: result, errorMessage: errorMessage, status: status };
  },

  poTypes: async (id) => {
    let errorMessage = "";
    let status = 200;
    let URL = REACT_APP_WMS_API_URL + "admin/api/po_types";

    let access_token = localStorage.getItem("access_token");
    let result = [];

    const getResult = await axios.get(
      URL,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
      .then(res => {
        if (res.status === 200 && res.data.length > 0) {
          result = res.data;
        }
      })
      .catch(error => {
        status = error.response.status;
        errorMessage = error.message;
      });
    return { data: result, errorMessage: errorMessage, status: status };
  },

  transportTypes: async (id) => {
    let errorMessage = "";
    let status = 200;
    let URL = REACT_APP_WMS_API_URL + "admin/api/transport_types";

    let access_token = localStorage.getItem("access_token");
    let result = [];

    const getResult = await axios.get(
      URL,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
      .then(res => {
        //console.log(res);
        if (res.status === 200 && res.data.result.length > 0) {
          result = res.data.result;
        }
      })
      .catch(error => {
        status = error.response.status;
        errorMessage = error.message;
      });
    return { data: result, errorMessage: errorMessage, status: status };
  },


  update: async (id, request) => {
    let errorMessage = "";
    let status = 200;

    let URL = REACT_APP_WMS_API_URL + "admin/api/delivery_orders/" + id;
    if (request.orderType === "PickingOrder") {
      URL = REACT_APP_WMS_API_URL + "admin/api/picking_orders/" + id;;
    }
    const access_token = localStorage.getItem("access_token");

    const result = await axios.put(
      URL,
      request,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${access_token}`
        }
      })
      .catch(error => {
        status = error.response.status;
        errorMessage = error.message;
      });

    return { data: result, errorMessage: errorMessage, status: status };
  },

  delete: async (id) => {
    let errorMessage = "";
    let status = 0;

    const URL = REACT_APP_WMS_API_URL + "admin/api/orders/" + id;
    const access_token = localStorage.getItem("access_token");

    const result = await axios.delete(
      URL,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${access_token}`
        }
      })
      .catch(error => {
        status = error.response.status;
        errorMessage = error.message;
      });

    return { data: result.data, errorMessage: errorMessage, status: status };
  },

  scan: async (request, type) => {
    const URL = REACT_APP_WMS_API_URL + "admin/api/scan/do_out";

    let access_token = localStorage.getItem("access_token");
    let result;

    if (type === 'get') {
      result = await axios.get(
        URL,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${access_token}`
          },
          params: request
        }
      ).then(res => {
        return res.data;
      }).catch(res => {
        return { status: 400, error: res.message };
      });
    } else {
      result = await axios.post(
        URL,
        request,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${access_token}`
          }
        }
      ).then(res => {
        return res.data;
      }).catch(res => {
        return { status: 400, error: res.message };
      });
    }

    return result;
  },

  exportPDF: async (id) => {
    let errorMessage = "";
    let status = 0;
    let access_token = localStorage.getItem("access_token");

    const URL = REACT_APP_WMS_API_URL + "admin/api/pdf/orders/picking_order_reference/" + id + ".pdf?authorization=" + access_token;

    const getResult = await axios.get(
      URL,
      {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .catch(error => {
        try {
          status = error.response.status;

          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = error.message;
          }
        } catch (err) {
          errorMessage = 'Service Unavailable!'
        }
      });

    return { data: getResult, errorMessage: errorMessage, status: status };
  },
  exportItemXLS: async (params) => {
    let errorMessage = "";
    let status = 0;

    const URL = REACT_APP_WMS_API_URL + "admin/api/xls/order_items/" + params;

    let access_token = localStorage.getItem("access_token");

    const getResult = await axios.get(
      URL,
      {
        responseType: 'blob',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      }).catch(error => {
        try {
          status = error.response.status;

          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = error.message;
          }
        } catch (err) {
          errorMessage = 'Service Unavailable!'
        }
      });

    return { data: getResult, errorMessage: errorMessage, status: status };
  }
}

export default deliveryOrderApi;