import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import SelfCollectionList from "./list";
import SelfCollectionForm from "./form";

const SelfCollection = ({ match }) => (
    <Switch>
      <Route
        path={`${match.url}/all`}
        render={props => <SelfCollectionList {...props} />}
      />
      <Route
        path={`${match.url}/create`}
        render={props => <SelfCollectionForm {...props} />}
      />
      <Route
        path={`${match.url}/edit`}
        render={props => <SelfCollectionForm {...props} />}
      />
      <Redirect to="/transaction/self-collection/all" />
    </Switch>
);

export default SelfCollection;
