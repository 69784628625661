import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

import { ReactComponent as AddIcon } from '../../assets/img/icons/btn_add.svg';
import { ReactComponent as RemoveIcon } from '../../assets/img/icons/delete.svg';
import { ReactComponent as ExportIcon } from '../../assets/img/icons/export.svg';

class IconButton extends Component {
	renderIcon(type) {
		switch (type) {
			case 'add':
				return <AddIcon />
			case 'remove':
				return <RemoveIcon />
			case 'export':
				return <ExportIcon />
			default:
				return <AddIcon />
		}
	}

	render() {
		return <Button
			className={this.props.className + " btn-icon"}
			onClick={this.props.onClick}
			type={this.props.type}
			variant="contained"
			title={this.props.title !== undefined ? this.props.title : ''}>
			{this.renderIcon(this.props.icon)} {this.props.label}
		</Button>
	}
}

export default IconButton;