import React, { Component } from "react";

import {
  CircularProgress,
  Typography
} from '@material-ui/core';

import {
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ButtonGroup,
  Button,
  Input,
  Col
} from "reactstrap";

import MUIDataTable from "mui-datatables";
import salesOrderApi from "../../../api/SalesOrder";
import apiUtil from "../../../api/apiUtil";

import moment from "moment";

class OutgoingHistoryDialog extends Component {
  constructor(props) {
    super(props);

    let modal = ((props.modal === true || props.modal === false) ? props.modal : false);
    let items = props.items ? props.items : [];
    this.state = {
      modal: modal,
      isLoading: false,
      tableState: {
        page: 1,
        rowsPerPage: 100,
        sortOrder: {}
      },
      searchTerm: '',
      items: items,
      totalItems: 0,
      selectedItems: []
    };

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modal !== this.state.modal) {
      if (nextProps.modal) { this.searchItem(); }
      this.setState({
        modal: nextProps.modal
      });
    }

    if (nextProps.items !== this.state.items) {
      this.setState({
        items: nextProps.items
      })
    }
  }

  handleClose = () => {
    this.setState({
      modal: false
    }, () => {
      if (this.props.onClose) {
        this.props.onClose(true);
      }
    });
  }

  searchItem = async () => {
    if (!this.props.item || this.props.item === "" || this.props.item === "0") {
      apiUtil.toast('Select item first.', 'error');
      this.handleClose();
      return false;
    }

    this.setState({
      isLoading: true
    });

    await salesOrderApi.getDetailItem(this.props.item, {
      page: this.state.tableState.page,
      take: this.state.tableState.rowsPerPage,
      query: this.state.searchTerm,
      customer_id: this.props.customer
    }).then(res => {
      this.setState({
        isLoading: false,
      });
      console.log(res)
      if (res.status !== 200) {
        apiUtil.toast(res.errorMessage, 'error');
        this.handleClose();
        return false;
      } else {
        let items = [];
        res.data.order_details.forEach(item => {
          items.push(item);
        });
        this.setState({
          items: items,
          totalItems: res.data.total
        });
      }
    })
      .catch(error => apiUtil.toast(error.message, 'error'));
  }

  handleSearchTerm = event => {
    this.setState({
      searchTerm: event.target.value
    });
  }

  changePage = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.searchItem();
  }

  sort = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.searchItem();
  }

  render() {
    const columns = [
      {
        name: "order_id",
        label: "DO No.",
        options: {
          customBodyRender: (value, rowData, index) => {
            if (rowData) {
              var data = this.state.items[rowData.rowIndex];
              if (data.order) {
                return data.order.reference_no ? data.order.reference_no : '';
              }
            }
            return '';
          }
        }
      },
      {
        name: "order_id",
        label: "Document No.",
        options: {
          customBodyRender: (value, rowData, index) => {
            if (rowData) {
              var data = this.state.items[rowData.rowIndex];
              if (data.order) {
                return data.order.document_no ? data.order.document_no : '';
              }
            }
            return '';
          }
        }
      },
      {
        name: "order.handling_out_date",
        label: "Out Date",
        options: {
          customBodyRender: (value, rowData, index) => {
            if (rowData) {
              var data = this.state.items[rowData.rowIndex];
              if (data.order && data.order.handling_out_date) {
                let date = new Date(data.order.handling_out_date);
                //return date.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
                return moment(date).format('DD-MM-YYYY');;
              }
            }
            return '';
          }
        }
      },
      {
        name: "quantity",
        label: "Quantity",
      },
      {
        name: "delivery_to",
        label: "Delivery Details",
        options: {
          customBodyRender: (value, rowData, index) => {
            if (rowData) {
              var data = this.state.items[rowData.rowIndex];
              if (data.order && data.order.delivery_address_line1) {
                return data.order.delivery_address_line1;
              }
            }
            return '';
          }
        }
      },
    ];

    const options = {
      selectableRows: 'none',
      rowsPerPage: this.state.tableState.rowsPerPage,
      serverSide: true,
      download: false,
      search: false,
      print: false,
      count: this.state.totalItems,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page + 1, tableState.sortOrder);
            break;
          case 'sort':
            this.sort(tableState.page + 1, tableState.sortOrder);
            break;
          default:
          //console.log('action not handled.');
        }
      }
    }

    return (
      <Modal isOpen={this.state.modal} toggle={this.handleClose} size="xl" zIndex={1300}>
        <ModalBody>
          <Row>
            {/* <Col xs="6">
              <Input type="text" className="form-control" placeholder="CR/Serial No/Part No/Lot No/Location" onChange={e => this.handleSearchTerm(e)} />
            </Col>

            <Col xs="6"><Button color="primary" onClick={(e) => this.searchItem(e)}>Search</Button></Col> */}

            <Col xs="12" className="mt-3">
              <MUIDataTable
                title={
                  <Typography variant="h6">
                    Delivery Order Out Details
                    {this.state.isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                  </Typography>}
                data={this.state.items}
                columns={columns}
                options={options}
              />
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button color="info" size="sm" outline={true} onClick={this.handleClose}>
            Done
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default OutgoingHistoryDialog;