import React, { useCallback } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import './assets/scss/main.scss';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthDataProvider from "./components/Auth/AuthDataProvider";
import RouteLayout from "./layout/Main/RouteLayout";

import PageNotFound from "./views/PageNotFound/PageNotFound";

import Customers from "./views/Customer";
import Vehicles from "./views/Vehicle";
import SalesOrder from "./views/SalesOrder";
import DeliveryOrder from "./views/DeliveryOrder";
import PickingOrder from "./views/PickingOrder";
import SelfCollection from "./views/SelfCollection";
import BalanceEnquiry from "./views/BalanceEnquiry";
import Report from "./views/Report";

// import SessionTimeout from './components/SessionTimeout';
import WorkerReminder from './components/WorkerReminder';

var isClicked = false;

function App() {
  // const beforeinstallpromptHandler = useCallback(
  //   (deferredPrompt) => {
  //     if(!isClicked){
  //       toast(
  //         <button 
  //           onClick={() => {
  //             deferredPrompt.prompt();

  //             deferredPrompt.userChoice
  //               .then((choiceResult) => {
  //                 isClicked = true;
  //             });
  //           }}  
  //         >Install</button>
  //       );
  //     }
  //   }, []
  // );

  // window.addEventListener('beforeinstallprompt', beforeinstallpromptHandler);

  return (
    <AuthDataProvider>
      <div className="App">
        <BrowserRouter basename="/">
          <Switch>
            <Route exact path="/">
              <Redirect to="/customers" />
            </Route>
            <RouteLayout path="/customers" component={Customers} />
            <RouteLayout path="/vehicles" component={Vehicles} />
            <RouteLayout path="/transaction/sales-order" component={SalesOrder} />
            <RouteLayout path="/transaction/delivery-order" component={DeliveryOrder} />
            <RouteLayout path="/transaction/picking-order" component={PickingOrder} />
            <RouteLayout path="/transaction/self-collection" component={SelfCollection} />
            <RouteLayout path="/transaction/balance-enquiry" component={BalanceEnquiry} />
            <RouteLayout path="/report" component={Report} />
            <Route component={PageNotFound} />
          </Switch>
        </BrowserRouter>
        <ToastContainer autoClose={false} />
      </div>
      {/*<SessionTimeout />*/}
      {/* <WorkerReminder /> */}
    </AuthDataProvider>
  );
}


export default App;
