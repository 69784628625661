import React from 'react';

import {
  Col,
  Row,
  Label,
  Button,
  ButtonGroup,
  FormGroup,
  Input
} from "reactstrap";

import {
  Box,
  CircularProgress
} from '@material-ui/core';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ImportItemDialog from "./ImportItemDialog";
import salesOrderApi from "../../../api/SalesOrder";
import apiUtil from "../../../api/apiUtil";

export default class AddItemForm extends React.Component {
  constructor(props) {
    super(props);
    let selectedCustomer = props.selectedCustomer;
    this.state = {
      importModal: false,
      isLoading: false,
      displayDate: '',
      formData: {
        id: null,
        delete: null,
        division: '',
        part_no: '',
        description: '',
        lot_no: '',
        serial_no: '',
        quantity: '',
        width: '',
        height: '',
        length: '',
        remarks: '',
        location: '',
        expiry_date: ''
      },
      selectedCustomer: selectedCustomer
    };
  }

  changeDate = date => {
    let formData = this.state.formData;
    formData.expiry_date = date.toLocaleDateString('en-US');
    this.setState({
      displayDate: date,
      formData: formData
    });
  }

  handleInputChange = event => {
    let formData = this.state.formData;
    formData[event.target.name] = event.target.value;
    this.setState({
      formData: formData
    });
  }

  handlePartNoChange = event => {
    let formData = this.state.formData.part_no;

    if (formData) {
      if (!this.props.selectedCustomer || this.props.selectedCustomer.id == 0) {
        apiUtil.toast('Please select customer.', 'error');
      } else {
        this.setState({
          isLoading: true
        });

        salesOrderApi.getDetail({
          page: 1,
          take: 9999,
          query: formData,
          customer_id: this.props.selectedCustomer.id
        }).then(res => {
          this.setState({
            isLoading: false,
          });

          if (res.status !== 200) {
            apiUtil.toast(res.errorMessage, 'error');
            return false;
          } else {
            let items = [];
            res.data.result.forEach(item => {
              items.push(item);
            });

            let samePart = items.filter(p => p.part_no == formData);
            if (samePart && samePart.length > 0) {
              this.state.formData.description = samePart[0].description;
              this.state.formData.length = samePart[0].length;
              this.state.formData.height = samePart[0].height;
              this.state.formData.width = samePart[0].width;
              this.forceUpdate()
            }
          }
        })
          .catch(error => apiUtil.toast(error.message, 'error'));
      }
    }
  }

  handlePartNoKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.handlePartNoChange();
    }
  }

  handleLocationChange = event => {
    let formData = this.state.formData;
    formData.location = event.value;
    this.setState({
      formData: formData
    });
  }

  addItem = event => {
    // if(!this.state.formData.division) {
    //   apiUtil.toast('Please input Division.', 'error');
    // } 
    var valid = true;
    if (!this.state.formData.part_no) {
      apiUtil.toast('Please input Part No.', 'error');
      valid = false;
    }
    if (!this.state.formData.description) {
      apiUtil.toast('Please input Description.', 'error');
      valid = false;
    }
    // if(!this.state.formData.lot_no) {
    //   apiUtil.toast('Please input Lot No.', 'error');
    // } 
    // if(!this.state.formData.serial_no) {
    //   apiUtil.toast('Please input Serial No.', 'error');
    // } 
    if (!this.state.formData.quantity) {
      apiUtil.toast('Please input Quantity.', 'error');
      valid = false;
    }
    if (!this.state.formData.length) {
      apiUtil.toast('Please input Length.', 'error');
      valid = false;
    }
    if (!this.state.formData.width) {
      apiUtil.toast('Please input Width.', 'error');
      valid = false;
    }
    if (!this.state.formData.height) {
      apiUtil.toast('Please input Height.', 'error');
      valid = false;
    }

    if (valid) {
      let formData = this.state.formData;

      this.props.addItem(formData);

      formData.id = null;
      formData.delete = null;

      this.setState({
        formData: formData
      });
    }
  }

  toggleImportModal = show => {
    this.setState({
      importModal: true
    });
  }

  importDialog = () => {
    return <ImportItemDialog
      modal={this.state.importModal}
      onImport={async (data) => {
        this.setState({
          importModal: false
        });

        if (data) {
          if (!this.props.selectedCustomer || this.props.selectedCustomer.id == 0) {
            apiUtil.toast('Please select customer.', 'error');
          } else {
            this.setState({
              isLoading: true
            });

            let error = false;
            for (let i = 0; i < data.length; i++) {
              if (data[i].part_no &&
                (!data[i].description ||
                  !data[i].length ||
                  !data[i].width ||
                  !data[i].height)
              ) {
                await salesOrderApi.getDetail({
                  page: 1,
                  take: 9999,
                  query: data[i].part_no,
                  customer_id: this.props.selectedCustomer.id
                }).then(res => {

                  if (res.status !== 200) {
                    apiUtil.toast(res.errorMessage, 'error');
                    this.props.addItem(data[i]);
                  } else {
                    let items = [];
                    res.data.result.forEach(item => {
                      items.push(item);
                    });

                    let samePart = items.filter(p => p.part_no == data[i].part_no);
                    if (samePart && samePart.length > 0) {
                      data[i].description = data[i].description ? data[i].description : samePart[0].description;
                      data[i].length = data[i].length ? data[i].length : samePart[0].length;
                      data[i].height = data[i].height ? data[i].height : samePart[0].height;
                      data[i].width = data[i].width ? data[i].width : samePart[0].width;
                    }
                  }

                  if (data[i].part_no &&
                    (!data[i].description ||
                      !data[i].length ||
                      !data[i].width ||
                      !data[i].height)
                  ) {
                    apiUtil.toast('Part No. ' + data[i].part_no + ' not found in the system. Please fill up the dimensions column in the excel.', 'error');
                    error = true;
                  }
                })
                  .catch(error => apiUtil.toast(error.message, 'error'));
              }
            }
            this.setState({
              isLoading: false,
            });

            if (!error) {
              this.props.addItem(data);
            }
          }
        }
      }}
      onClose={() => {
        this.setState({
          importModal: false
        });
      }}
    />
  }

  render() {
    return (
      <Row>
        <Col xs="12">
          <h4 className="font-weight-bold mb-4">Add New Item</h4>
        </Col>

        <Col xs="6">
          <FormGroup>
            <Label className="control-label">Division</Label>
            <Input name="division" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.division}
              disabled={this.state.isLoading} />
          </FormGroup>

          <FormGroup>
            <Label className="control-label">Part No*</Label>
            <Input name="part_no" className="form-input form-control" onChange={this.handleInputChange} onKeyDown={this.handlePartNoKeyDown} onBlur={this.handlePartNoChange} value={this.state.formData.part_no}
              disabled={this.state.isLoading} />
          </FormGroup>

          <FormGroup>
            <Label className="control-label">Description*</Label>
            <Input name="description" component="textarea" rows="5" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.description}
              disabled={this.state.isLoading} />
          </FormGroup>

          <FormGroup>
            <Label className="control-label">Lot No</Label>
            <Input name="lot_no" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.lot_no}
              disabled={this.state.isLoading} />
          </FormGroup>

          <FormGroup>
            <Label className="control-label">Serial No</Label>
            <Input name="serial_no" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.serial_no}
              disabled={this.state.isLoading} />
          </FormGroup>

          <FormGroup>
            <Label className="control-label">Expiry Date</Label>
            <div className="form-input">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className="form-control"
                selected={this.state.displayDate}
                onChange={date => this.changeDate(date)}
                disabled={this.state.isLoading}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <Label className="control-label">Location</Label>
            <Input name="location" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.location}
              disabled={this.state.isLoading} />
            {/* <Select className="form-input no-border" options={locationData} onChange={this.handleLocationChange} /> */}
          </FormGroup>
        </Col>

        <Col xs="6">
          <FormGroup>
            <Label className="control-label">Quantity*</Label>
            <Input name="quantity" className="form-input form-control" type="number" onChange={this.handleInputChange} value={this.state.formData.quantity}
              disabled={this.state.isLoading} />
          </FormGroup>

          <FormGroup>
            <Label className="control-label">Volume*</Label>
            <Input name="length" placeholder="Length (cm)" className="form-input form-control" type="number" onChange={this.handleInputChange} value={this.state.formData.length}
              disabled={this.state.isLoading} />
            <Input name="width" placeholder="Width (cm)" className="form-input form-control" type="number" onChange={this.handleInputChange} value={this.state.formData.width}
              disabled={this.state.isLoading} />
            <Input name="height" placeholder="Height (cm)" className="form-input form-control" type="number" onChange={this.handleInputChange} value={this.state.formData.height}
              disabled={this.state.isLoading} />
          </FormGroup>

          {this.state.selectedCustomer.email_alert_type_id == 4 && <FormGroup>
            <Label className="control-label">Minimum Quantity Limit*</Label>
            <Input name="minimum_quantity" className="form-input form-control" type="number" onChange={this.handleInputChange} value={this.state.formData.minimum_quantity}
              disabled={this.state.isLoading} />
          </FormGroup>}

          <FormGroup>
            <Label className="control-label">Remarks</Label>
            <Input name="remarks" component="textarea" rows="5" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.remarks}
              disabled={this.state.isLoading} />
          </FormGroup>
        </Col>

        <Col xs="12" className="mt-4">
          <ButtonGroup>
            <Button
              className="btn-multiple-state mr-3"
              color="success"
              outline={true}
              type="button"
              disabled={this.state.isLoading}
              onClick={(e) => this.addItem(e)}>
              Add Item
              {this.state.isLoading &&
                <CircularProgress size={20} />}
            </Button>

            <Button
              className="btn-multiple-state mr-3"
              color="info"
              outline={true}
              type="button"
              disabled={this.state.isLoading}
              onClick={() => this.toggleImportModal(true)}>
              Import from Excel
              {this.state.isLoading &&
                <CircularProgress size={20} />}
            </Button>
          </ButtonGroup>
        </Col>
        {this.importDialog()}
      </Row>
    );
  }
}