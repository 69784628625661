import axios from 'axios';

const { 
  REACT_APP_WMS_API_URL
} = process.env;

const reportApi = {
  getReport: async (request, type) => {
    let errorMessage = "";
    let params = "customer_id=" + request.customer_id;

    if (request.start_date !== "") {
      params += "&start_date=" + request.start_date;
    }

    if (request.end_date !== "") {
      params += "&end_date=" + request.end_date;
    }

    let URL = "";

    if (type === 'handling_fee') {
      URL = REACT_APP_WMS_API_URL + "admin/api/report/handling_fee?" + params;
    } else {
      URL = REACT_APP_WMS_API_URL + "admin/api/report/rental_fee?" + params;
    }

    let access_token = localStorage.getItem("access_token");

    const result = await axios.get(
      URL,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
    .catch( error => {
      errorMessage = error.message;
    });

    return { data: result, errorMessage: errorMessage };
  },
  
  exportReportXLS: async (params, type) => {
    let errorMessage = "";
    let status = 0;
    let URL = "";

    params.download_excel = true;

    if (type === 'handling_fee') {
      URL = REACT_APP_WMS_API_URL + "admin/api/report/handling_fee";
    } else {
      URL = REACT_APP_WMS_API_URL + "admin/api/report/rental_fee";
    }

    let access_token = localStorage.getItem("access_token");

    const result = await axios.get(
      URL,
      {
        params: params,
        responseType: 'blob',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
    .catch( error => {
      try {
        status = error.response.status;

        if (error.response) {
          // Request made and server responded
          errorMessage = error.response.data;
        } else if (error.request) {
          // The request was made but no response was received
          errorMessage = error.request;
        } else {
          // Something happened in setting up the request that triggered an Error
          errorMessage = error.message;
        }
      } catch (err) {
        errorMessage = 'Service Unavailable!'
      }
    });

    return { data: result, errorMessage: errorMessage, status: status };
  },

  getAging: async (request) => {
    let errorMessage = "";
    let params = "customer_id=" + request.customer_id;

    if (request.expiry !== "") {
      params += "&expiry=" + request.expiry;
    }

    const URL = REACT_APP_WMS_API_URL + "admin/api/report/handling_expiry?" + params;

    let access_token = localStorage.getItem("access_token");

    const result = await axios.get(
      URL,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
    .catch( error => {
      errorMessage = error.message;
    });

    return { data: result, errorMessage: errorMessage };
  },

  getMonthly: async (request) => {
    let errorMessage = "";
    let params = "start_date=" + request.start_date + "&end_date=" + request.end_date;

    const URL = REACT_APP_WMS_API_URL + "admin/api/report/monthly_summary?" + params;

    let access_token = localStorage.getItem("access_token");

    const result = await axios.get(
      URL,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
    .catch( error => {
      errorMessage = error.message;
    });

    return { data: result, errorMessage: errorMessage };
  }
}

export default reportApi;