import React, { Component } from "react";

import {
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    Label,
    Button,
    FormGroup,
    Input,
    Col
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { locationData } from '../../../data/misc';

import apiUtil from "../../../api/apiUtil";

class AddVehicleChecklistDialog extends Component {
    constructor(props) {
        super(props);

        let modal = ((props.modal === true || props.modal === false) ? props.modal : false);
        let vehicleId = props.vehicleId;
        this.state = {
            modal: modal,
            displayDate: '',
            formData: {
                vehicleId: vehicleId,
                odometer_reading: null,
                date_recorded: null,
                vehicle_check_list_fields: []
            },
            checklistOptions: [
                {
                    label: 'Satisfactory/available',
                    value: 1
                },
                {
                    label: 'Defective/missing',
                    value: 2
                },
                {
                    label: 'Critical fault',
                    value: 3
                },
                {
                    label: 'Not applicable',
                    value: 4
                }
            ]
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.modal !== this.state.modal) {
            this.setState({
                modal: nextProps.modal,
            });

            this.initFormData();
        }
    }

    handleClose = () => {
        this.setState({
            modal: false
        }, () => {
            if (this.props.onClose) {
                this.props.onClose(true);
            }
        });
    }

    handleSave = () => {
        var isValid = true;
        
        if (this.state.formData.odometer_reading == null) {
            apiUtil.toast('Please input odometer', 'error');
            isValid = false;
        }

        for (let i = 0; i < this.state.formData.vehicle_check_list_fields.length; i++) {
            if (!this.state.formData.vehicle_check_list_fields[i].vehicle_check_list_field_selection_id
                || this.state.formData.vehicle_check_list_fields[i].vehicle_check_list_field_selection_id <= 0) {
                apiUtil.toast('Please input ' + this.state.formData.vehicle_check_list_fields[i].vehicle_check_list_master_field_label, 'error');
                isValid = false;
                break;
            }
        }

        if (isValid) {
            this.setState({
                modal: false
            }, () => {
                if (this.props.onSave) {
                    this.props.onSave(this.state.formData);
                }
            });
        }
    }

    handleInputChange = event => {
        let formState = this.state.formData;
        formState[event.target.name] = event.target.value;

        this.setState({
            formData: formState
        });
    }
    
    initFormData = () => {
        let formData = this.state.formData;

        formData.vehicleId = this.props.vehicleId;

        formData.vehicle_check_list_fields = [
            {
                vehicle_check_list_master_field_label: 'Battery Water/Fuel Level',
                vehicle_check_list_master_field_id: 45,
                vehicle_check_list_field_selection_id: null
            },
            {
                vehicle_check_list_master_field_label: 'Oil (engine and hydraulic cylinders)',
                vehicle_check_list_master_field_id: 46,
                vehicle_check_list_field_selection_id: null
            },
            {
                vehicle_check_list_master_field_label: 'Tyres',
                vehicle_check_list_master_field_id: 47,
                vehicle_check_list_field_selection_id: null
            },
            {
                vehicle_check_list_master_field_label: 'Leak (Water, Oil)',
                vehicle_check_list_master_field_id: 48,
                vehicle_check_list_field_selection_id: null
            },
            {
                vehicle_check_list_master_field_label: 'Service Breaks',
                vehicle_check_list_master_field_id: 49,
                vehicle_check_list_field_selection_id: null
            },
            {
                vehicle_check_list_master_field_label: 'Overhead Guard',
                vehicle_check_list_master_field_id: 50,
                vehicle_check_list_field_selection_id: null
            },
            {
                vehicle_check_list_master_field_label: 'Lift Chains',
                vehicle_check_list_master_field_id: 51,
                vehicle_check_list_field_selection_id: null
            },
            {
                vehicle_check_list_master_field_label: 'Lifting Forks',
                vehicle_check_list_master_field_id: 52,
                vehicle_check_list_field_selection_id: null
            },
            {
                vehicle_check_list_master_field_label: 'Mirrors',
                vehicle_check_list_master_field_id: 53,
                vehicle_check_list_field_selection_id: null
            },
            {
                vehicle_check_list_master_field_label: 'Lights (including flashing beacon)',
                vehicle_check_list_master_field_id: 54,
                vehicle_check_list_field_selection_id: null
            },
            {
                vehicle_check_list_master_field_label: 'Audible Warning System (reversing)',
                vehicle_check_list_master_field_id: 55,
                vehicle_check_list_field_selection_id: null
            },
            {
                vehicle_check_list_master_field_label: 'Steering Gear',
                vehicle_check_list_master_field_id: 56,
                vehicle_check_list_field_selection_id: null
            },
            {
                vehicle_check_list_master_field_label: 'Parking Brakes',
                vehicle_check_list_master_field_id: 57,
                vehicle_check_list_field_selection_id: null
            }
        ];

        this.setState({
            formData: formData
        });
    }

    handleInputChange = event => {
        let formState = this.state.formData;
        formState[event.target.name] = event.target.value;

        this.setState({
            formData: formState
        });
    }

    handleChecklistChange = (event, index) => {
        let formState = this.state.formData;
        formState.vehicle_check_list_fields[index].vehicle_check_list_field_selection_id = event.value;

        this.setState({
            formData: formState
        });
    }

    renderCheckList = () => {
        let dataList = this.state.formData.vehicle_check_list_fields;
        return (
            <div style={dataList && dataList?.length > 0 ? { display: "block" } : { display: "none" }}>
                {dataList.map((item, key) => {
                    return (
                        <FormGroup>
                            <Label className="control-label">{item.vehicle_check_list_master_field_label}</Label>
                            <Select
                                className="form-input no-border"
                                options={this.state.checklistOptions}
                                onChange={(e) => {
                                    this.handleChecklistChange(e, key);
                                }}
                            />
                        </FormGroup>
                    );
                })}
            </div>
        );
    };

    render() {
        return (
            <Modal isOpen={this.state.modal} toggle={this.handleClose} size="lg" className="modal-dialog-centered">
                <ModalBody>
                    <FormGroup>
                        <Label className="control-label">Odometer</Label>
                        <Input name="odometer_reading" type="number" className="form-input form-control mb-4" onChange={(e) => this.handleInputChange(e)} value={this.state.formData.odometer_reading} required={true} />
                    </FormGroup>
                    {this.renderCheckList()}
                </ModalBody>

                <ModalFooter>
                    <Button type="submit" color="success" size="sm" outline={true} onClick={this.handleSave}>
                        Save
                    </Button>
                    <Button type="button" color="danger" size="sm" outline={true} onClick={this.handleClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default AddVehicleChecklistDialog;