import React from 'react';

import {
  Col,
  Row,
  Label,
  Button,
  ButtonGroup,
  FormGroup,
  Input,
  Card,
  CardBody,
} from "reactstrap";
import Box from "@material-ui/core/Box";
import {
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  Divider
} from "@material-ui/core";
import Select from 'react-select';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { ReactComponent as ExportIcon } from '../../../assets/img/icons/export.svg';

import IconButton from "../../../components/Button/IconButton";
import CustomDateRangePicker from '../../../components/CustomFields/CustomDateRangePicker';
import AddVehicleChecklistDialog from "./AddVehicleChecklistDialog";

import moment from "moment";
import apiUtil from "../../../api/apiUtil";
import api from "../../../api/api.js";

export default class VehicleChecklist extends React.Component {
  constructor(props) {
    super(props);
    let vehicleId = props.vehicleId;
    this.state = {
      isLoading: false,
      vehicleId: vehicleId,
      recordedIdList: [],
      data: [],
      sourceData: [],
      currentSubmittedId: null,
      currentSubmittedLabel: null,
      dateRange: {
        startDate: moment().startOf('day'),
        endDate: moment().endOf('day'),
      },
      addVehicleChecklistModal: false,
    };
  }

  /* API */
  componentWillMount() {
    this.callReadApi(this.state.dateRange.startDate, this.state.dateRange.endDate);
  }

  callReadApi = (startDate, endDate) => {
    let param = {
      start_date: moment(startDate).startOf('day').format(apiUtil.getDefaultDateTimeFormat()),
      end_date: moment(endDate).endOf('day').format(apiUtil.getDefaultDateTimeFormat()),
      vehicle_id: this.state.vehicleId,
      description: 'Vehicle checklist'
    };
    api.read("vehicle_check_list_records", param).then(
      (results) => {
        apiUtil.parseResult(
          results,
          (data) => {
            const dataArr = data.result && data.result.length > 0 ? data.result : [];
            const dataList = dataArr.filter((item) => {
              if (item.vehicle_check_list_fields.length > 0) {
                return item;
              }
            });
            const ids = dataArr.map((item) => {
              return { value: item.id, label: moment(item.created_at).format(apiUtil.getDefaultDateTimeFormat()) };
            });

            this.setState({
              recordedIdList: ids,
              data: dataList.length > 0 ? [dataList[0]] : [],
              sourceData: dataList,
              currentSubmittedId: dataList.length > 0 ? dataList[0].id : null,
              currentSubmittedLabel: dataList.length > 0 ? moment(dataList[0].created_at).format(apiUtil.getDefaultDateTimeFormat()) : null
            });
          },
          (error, type) => {
            this.setState({
              data: []
            });
          }
        );
      }
    );
  };

  callExportApi = (startDate, endDate) => {
    let param = {
      start_date: moment(startDate).format(apiUtil.getDefaultDateFormat()),
      end_date: moment(endDate).format(apiUtil.getDefaultDateFormat()),
      vehicle_id: this.state.vehicleId,
      description: 'Vehicle checklist'
    };
    api.exportXLS("export_check_list", param).then(
      (results) => {
        apiUtil.parseResult(
          results,
          (response) => {
            apiUtil.saveToFile(
              response,
              `Vehicle Checklist ${moment(startDate).format("YYYY-MM-DD")} - ${moment(
                endDate
              ).format("YYYY-MM-DD")}.xls`
            );
          },
          (error, type) => {
            console.log("error", error, type);
            apiUtil.toast(error, 'warning', 'error');
          }
        );
      }
    );
  };

  renderData = (data) => {
    return (
      <>
        {data.map((item, key) => {
          return (
            <>
              <span style={{ display: "block" }}>
                {item.vehicle_check_list_master_field && (
                  <div style={{ fontWeight: 'bold' }}>
                    {key === 0 && item.vehicle_check_list_master_field.vehicle_check_list_category?.name}
                  </div>
                )}
                {item.vehicle_check_list_master_field && (
                  <div style={{ display: 'flex' }}>
                    <span style={{ width: "50%", display: "inline-block" }}>
                      {item.vehicle_check_list_master_field?.name}
                    </span>
                    :{" "}
                    {item.vehicle_check_list_master_field?.name == 'Image' && item.text_field && item.text_field != ''
                      ? <div style={{ width: "49%", display: "inline-block" }}>

                      </div>
                      : <span>{item.vehicle_check_list_field_selection?.name}</span>}
                  </div>
                )}
              </span>
            </>
          );
        })}
      </>
    );
  };

  renderCheckList = () => {
    let dataList = this.state.data;
    return (
      <div style={dataList && dataList?.length > 0 ? { display: "block" } : { display: "none" }}>
        <div style={{ margin: "13px auto" }}>
          Submitted by: {dataList && dataList?.length > 0 && dataList[0].worker ? dataList[0].worker.first_name.trim() + " " + dataList[0].worker.last_name.trim() : "-"}
        </div>
        {dataList.map((itemList) => {
          return this.renderData(itemList.vehicle_check_list_fields);
        })}
      </div>
    );
  };

  renderData = (data) => {
    return (
      <>
        {data.map((item, key) => {
          return (
            <>
              <span style={{ display: "block" }}>
                {item.vehicle_check_list_master_field && (
                  <div style={{ fontWeight: 'bold' }}>
                    {key === 0 && item.vehicle_check_list_master_field.vehicle_check_list_category?.name}
                  </div>
                )}
                {item.vehicle_check_list_master_field && (
                  <div style={{ display: 'flex' }}>
                    <span style={{ width: "50%", display: "inline-block" }}>
                      {item.vehicle_check_list_master_field?.name}
                    </span>
                    :{" "}
                    {item.vehicle_check_list_master_field?.name == 'Image' && item.text_field && item.text_field != ''
                      ? <div style={{ width: "49%", display: "inline-block" }}>
                        <GridList>
                          {item.text_field.split(',').map((item, i) => {
                            return <GridListTile key={i}>
                              <img src={item} alt={'vehicle-img'} />
                              <GridListTileBar
                                title={<Box style={{ color: 'white' }}>{item}</Box>}
                                actionIcon={
                                  <IconButton
                                    className={'icon-white'}
                                    onClick={() => {
                                      var win = window.open(item, '_blank');
                                      win.focus();
                                    }}
                                  >
                                    <OpenInNewIcon />
                                  </IconButton>
                                }
                              />
                            </GridListTile>
                          })}
                        </GridList>
                      </div>
                      : <span>{item.vehicle_check_list_field_selection?.name}</span>}
                  </div>
                )}
              </span>
            </>
          );
        })}
      </>
    );
  };

  addNewButton = () => {
    if (apiUtil.checkAccess("VehicleChecklistCreate")) {
      return <IconButton className="btn-theme btn-theme-1 float-right" label="Add New" icon="add" onClick={() => this.openAddVehicleChecklistDialog()} />;
    }
    else {
      return '';
    }
  }

  openAddVehicleChecklistDialog = () => {
    this.setState({
      addVehicleChecklistModal: true
    });
  }

  vehicleChecklistDialog = () => {
    return <AddVehicleChecklistDialog
      modal={this.state.addVehicleChecklistModal}
      vehicleId={this.state.vehicleId}
      onClose={() => {
        this.setState({
          addVehicleChecklistModal: false
        });
      }}
      onSave={(formData) => {
        formData.date_recorded = moment().utc().format(apiUtil.getDefaultDateTimeFormat());

        let request = {
          vehicle_id: parseInt(formData.vehicleId),
          odometer_reading: parseFloat(formData.odometer_reading),
          date_recorded: formData.date_recorded,
          description: 'Vehicle checklist',
          vehicle_check_list_fields: []
        }

        for (let i = 0; i < formData.vehicle_check_list_fields.length; i++) {
          request.vehicle_check_list_fields.push({
            vehicle_check_list_master_field_id: formData.vehicle_check_list_fields[i].vehicle_check_list_master_field_id,
            vehicle_check_list_field_selection_id: formData.vehicle_check_list_fields[i].vehicle_check_list_field_selection_id
          });
        }

        console.log(request);

        api.create("vehicle_check_list_records", request).then(
          (results) => {
            console.log(results);
          }
        );
        this.setState({
          addVehicleChecklistModal: false
        });
      }}
    />
  }

  render() {
    return (
      <Card className="mt-3">
        <CardBody className="pt-0">
          <Row>
            <Col xs="12">
              <h4 className="font-weight-bold mb-4">Vehicle Checklist</h4>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col md="4">
              <CustomDateRangePicker
                range={this.state.dateRange}
                onChange={(range) => {
                  this.setState({
                    dateRange: range,
                  });
                  this.callReadApi(range.startDate, range.endDate);
                }}
              />
            </Col>
            {/* <CustomButton
              color={'primary'}
              onClick={() => {
                this.callExportApi(this.state.dateRange.startDate, this.state.dateRange.endDate);
              }}
            >
              <ExportIcon />
              <Box display={'inline-block'} pl={1}>Export</Box>
            </CustomButton> */}
            <Col>
              {this.addNewButton()}
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <FormGroup>
                <Label className="control-label">
                  Submitted list
                </Label>
                <Select value={{ label: this.state.currentSubmittedLabel, value: this.state.currentSubmittedId }}
                  className="form-input no-border"
                  options={this.state.recordedIdList}
                  onChange={(e) => {
                    const newData = this.state.sourceData.filter((item) => item.id === e.value);

                    this.setState({
                      currentSubmittedId: e.value,
                      currentSubmittedLabel: e.label,
                      data: newData
                    });
                  }} />
              </FormGroup>
            </Col>
            <Col>
              <IconButton className="btn-theme btn-theme-2" label="Export" icon="export" onClick={() => this.callExportApi(this.state.dateRange.startDate, this.state.dateRange.endDate)} />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              {this.renderCheckList()}
            </Col>
          </Row>
        </CardBody>
        {this.vehicleChecklistDialog()}
      </Card>
    );
  }
}