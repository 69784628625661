import React, { Component } from "react";
import { 
  Typography
} from '@material-ui/core';
import { Container, Col, Row } from "reactstrap";
import MUIDataTable from "mui-datatables";

class MonthlyReportTable extends Component {
	constructor (props) {
		super(props);
		this.state = {
      title: this.props.reportData.result.Title,
			total: parseFloat(this.props.reportData.result.Grand_Total),
			reports: this.props.reportData.result.Data
		}
	}

	render () {
		const columns = [
      {
        name: "Company_Name",
        label: "Company Name"
      },
      {
        name: "M3",
        label: "M3"
      },
      {
        name: "Handling($)",
        label: "Handling ($)",
        options: {
          customBodyRender: (value) => {
            return parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
          }
        }
      },
      {
        name: "Rental($)",
        label: "Rental ($)",
        options: {
          customBodyRender: (value) => {
            return parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
          }
        }
      },
      {
        name: "Total($)",
        label: "Total ($)",
        options: {
          customBodyRender: (value) => {
            return parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
          }
        }
      },
      {
      	name: "Per(%)",
      	label: "Percent (%)"
      }
    ];

    const options = {
      selectableRows: 'none',
      rowsPerPage: 10,
      serverSide: false
    }

    return (
    	<Container fluid={true}>
      <Row className="mt-3">
        <Col xs="12">
          <div className="text-center mt-3 mb-3">
            <h3>Grand Total</h3>
            <h2 className="font-weight-bold h2">{this.state.total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h2>
          </div>
          
          <MUIDataTable
            title={
              <Typography variant="h6">
              {this.state.title}
              </Typography>
            }
            className="mt-3"
            data={this.state.reports}
            columns={columns}
            options={options}
          />

        	
        </Col>
      </Row>
      </Container>
    );
  }
}

export default MonthlyReportTable;