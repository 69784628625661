import React, { Component } from "react";
import { Box } from '@material-ui/core';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import ProgressButton from "../../components/Button/ProgressButton";
import Select from 'react-select';

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Loading from "react-fullscreen-loading";

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";

import apiUtil from "../../api/apiUtil";
import api from "../../api/api.js";
import moment from "moment";

const VehicleSchema = Yup.object({
  odometer_reading: Yup.number()
    .nullable()
    .typeError('Value must be a number')
    .positive('Value must be greater than zero'),
});

class VehicleCheclistForm extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      dataId: '',
      errors: {},
      isLoading: false,
      pageLoading: false,
      oldData: null,
      vehicleList: [],
      selectedVehicle: {
        id: '0',
        name: '',
      },
      formData: {
        vehicleId: null,
        odometer_reading: null,
        date_recorded: null,
        vehicle_check_list_fields: []
      },
      checklistOptions: [
        {
          label: 'Satisfactory/available',
          value: 1
        },
        {
          label: 'Defective/missing',
          value: 2
        },
        {
          label: 'Critical fault',
          value: 3
        },
        {
          label: 'Not applicable',
          value: 4
        }
      ]
    };
  }

  componentWillMount() {
    this._isMounted = true;
  }

  componentDidMount = () => {
    this.initFormData();
    this.getVehicles();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getVehicles = async () => {
    this.setState({ isLoading: true });
    let params = {
      page: 1,
      take: 99999,
      app_id: 1
    };

    api.read('vehicles', params, null, 'admin').then((results) => {
      apiUtil.parseResult(results, (data) => {
        const dataArr = (data.result && data.result.length > 0) ? data.result : [];

        let vehicleList = [];

        dataArr.forEach((val) => {
          vehicleList.push({
            value: val.id,
            label: val.plate_no,
            name: val.plate_no,
          });
        });

        this.setState({
          vehicleList: vehicleList,
          isLoading: false
        });
      }, (error, type) => {
        apiUtil.toast(error.errorMessage, 'error');
        this.setState({
          isLoading: false,
        });
      });
    });
  }

  populateData = data => {
    let formData = this.state.formData;

    for (const key in formData) {
      formData[key] = data[key];
    }

    this.setState({
      oldData: data,
      pageLoading: false,
      formData: formData,
    });
  }

  initFormData = () => {
    let formData = this.state.formData;

    formData.vehicleId = null;
    formData.odometer_reading = null;
    formData.date_recorded = null;

    formData.vehicle_check_list_fields = [
      {
        vehicle_check_list_master_field_label: 'Battery Water/Fuel Level',
        vehicle_check_list_master_field_id: 45,
        // Default to Satisfactory/available
        vehicle_check_list_field_selection_id: 1
      },
      {
        vehicle_check_list_master_field_label: 'Oil (engine and hydraulic cylinders)',
        vehicle_check_list_master_field_id: 46,
        // Default to Satisfactory/available
        vehicle_check_list_field_selection_id: 1
      },
      {
        vehicle_check_list_master_field_label: 'Tyres',
        vehicle_check_list_master_field_id: 47,
        // Default to Satisfactory/available
        vehicle_check_list_field_selection_id: 1
      },
      {
        vehicle_check_list_master_field_label: 'Leak (Water, Oil)',
        vehicle_check_list_master_field_id: 48,
        // Default to Satisfactory/available
        vehicle_check_list_field_selection_id: 1
      },
      {
        vehicle_check_list_master_field_label: 'Service Breaks',
        vehicle_check_list_master_field_id: 49,
        // Default to Satisfactory/available
        vehicle_check_list_field_selection_id: 1
      },
      {
        vehicle_check_list_master_field_label: 'Overhead Guard',
        vehicle_check_list_master_field_id: 50,
        // Default to Satisfactory/available
        vehicle_check_list_field_selection_id: 1
      },
      {
        vehicle_check_list_master_field_label: 'Lift Chains',
        vehicle_check_list_master_field_id: 51,
        // Default to Satisfactory/available
        vehicle_check_list_field_selection_id: 1
      },
      {
        vehicle_check_list_master_field_label: 'Lifting Forks',
        vehicle_check_list_master_field_id: 52,
        // Default to Satisfactory/available
        vehicle_check_list_field_selection_id: 1
      },
      {
        vehicle_check_list_master_field_label: 'Mirrors',
        vehicle_check_list_master_field_id: 53,
        // Default to Satisfactory/available
        vehicle_check_list_field_selection_id: 1
      },
      {
        vehicle_check_list_master_field_label: 'Lights (including flashing beacon)',
        vehicle_check_list_master_field_id: 54,
        // Default to Satisfactory/available
        vehicle_check_list_field_selection_id: 1
      },
      {
        vehicle_check_list_master_field_label: 'Audible Warning System (reversing)',
        vehicle_check_list_master_field_id: 55,
        // Default to Satisfactory/available
        vehicle_check_list_field_selection_id: 1
      },
      {
        vehicle_check_list_master_field_label: 'Steering Gear',
        vehicle_check_list_master_field_id: 56,
        // Default to Satisfactory/available
        vehicle_check_list_field_selection_id: 1
      },
      {
        vehicle_check_list_master_field_label: 'Parking Brakes',
        vehicle_check_list_master_field_id: 57,
        // Default to Satisfactory/available
        vehicle_check_list_field_selection_id: 1
      }
    ];

    this.setState({
      selectedVehicle: {
        id: '0',
        name: '',
      },
      formData: formData
    });
  }

  renderCheckList = () => {
    let dataList = this.state.formData.vehicle_check_list_fields;
    return (
      <div style={dataList && dataList?.length > 0 ? { display: "block" } : { display: "none" }}>
        {dataList.map((item, key) => {
          let selectedChecklist = this.state.checklistOptions[this.state.checklistOptions.findIndex(x => x.value == item.vehicle_check_list_field_selection_id)];
          let label = selectedChecklist ? selectedChecklist.label : null

          return (
            <FormGroup>
              <Label className="control-label">{item.vehicle_check_list_master_field_label}</Label>
              <Select
                value={{ label: label, value: item.vehicle_check_list_field_selection_id }}
                className="form-input no-border"
                options={this.state.checklistOptions}
                onChange={(e) => {
                  this.handleChecklistChange(e, key);
                }}
              />
            </FormGroup>
          );
        })}
      </div>
    );
  };

  handleInputChange = event => {
    let formState = this.state.formData;
    formState[event.target.name] = event.target.value;

    this.setState({
      formData: formState
    });
  }

  handleChecklistChange = (event, index) => {
    let formState = this.state.formData;
    formState.vehicle_check_list_fields[index].vehicle_check_list_field_selection_id = event.value;

    this.setState({
      formData: formState
    });
  }

  handleVehicleChange = (event) => {
    let formState = this.state.formData;
    formState.vehicle_id = event.value;

    this.setState({
      selectedVehicle: {
        id: event.value,
        name: event.name
      }
    });
  }

  handleSubmit = values => {
    var isValid = true;

    let formData = this.state.formData;

    formData.vehicleId = this.state.selectedVehicle.id;

    if (formData.vehicleId == null || formData.vehicleId <= 0) {
      apiUtil.toast('Please input vehicle', 'error');
      isValid = false;
    }

    // Odometer now nullable
    // if (formData.odometer_reading == null) {
    //   apiUtil.toast('Please input odometer', 'error');
    //   isValid = false;
    // }

    for (let i = 0; i < formData.vehicle_check_list_fields.length; i++) {
      if (!formData.vehicle_check_list_fields[i].vehicle_check_list_field_selection_id
        || formData.vehicle_check_list_fields[i].vehicle_check_list_field_selection_id <= 0) {
        apiUtil.toast('Please input ' + formData.vehicle_check_list_fields[i].vehicle_check_list_master_field_label, 'error');
        isValid = false;
        break;
      }
    }

    if (isValid) {
      formData.date_recorded = moment().utc().format(apiUtil.getDefaultDateTimeFormat());

      let request = {
        vehicle_id: parseInt(formData.vehicleId),
        odometer_reading: parseFloat(formData.odometer_reading),
        date_recorded: formData.date_recorded,
        description: 'Vehicle checklist',
        vehicle_check_list_fields: []
      }

      for (let i = 0; i < formData.vehicle_check_list_fields.length; i++) {
        request.vehicle_check_list_fields.push({
          vehicle_check_list_master_field_id: formData.vehicle_check_list_fields[i].vehicle_check_list_master_field_id,
          vehicle_check_list_field_selection_id: formData.vehicle_check_list_fields[i].vehicle_check_list_field_selection_id
        });
      }

      api.create("vehicle_check_list_records", request).then(
        (results) => {
          apiUtil.parseResult(results, (data) => {
            if (data.status) {
              apiUtil.toast("Vehicle checklist created.", 'success');
              this.initFormData();
            } else {
              apiUtil.toast(data.message, 'error');
            }
          }, (error, type) => {
            apiUtil.toast(error.errorMessage, 'error');
            this.setState({
              isLoading: false,
            });
          });
        }
      );
    }
  }

  render() {
    return (
      <Box className="dashboard-page">
        <StickyPageHeader>
          <BreadCrumb />
          <h1 className="page-title">{"Create Vehicle Checklist"}</h1>
        </StickyPageHeader>

        <Loading loading={this.state.pageLoading} background="#f5f5f5" loaderColor="#e89cae" />
        <Row>
          <Formik
            enableReinitialize={true}
            initialValues={{
              vehicle_id: this.state.vehicle_id,
              odometer_reading: this.state.formData.odometer_reading
            }}
            validationSchema={VehicleSchema}
            onSubmit={this.handleSubmit}>
            {({
              handleSubmit,
              errors,
              touched,
            }) => (
              <Form>
                <Col md={{ size: 12 }}>
                  <Container className={this.state.readOnly ? "overlay" : ""}>
                    <Card className="mt-3">
                      <CardBody className="pt-0">
                        <FormGroup>
                          <Label className="control-label">Vehicle</Label>
                          <Select
                            value={{ label: this.state.selectedVehicle.name, value: this.state.selectedVehicle.id }}
                            className="form-input no-border"
                            options={this.state.vehicleList}
                            onChange={(e) => this.handleVehicleChange(e)} />
                        </FormGroup>
                        <FormGroup>
                          <Label className="control-label">Odometer</Label>
                          <Field className="form-control form-input" type="number" name="odometer_reading" value={this.state.formData.odometer_reading || ''} onChange={this.handleInputChange} />
                          {
                            errors.odometer_reading && touched.odometer_reading ? (
                              <div className="invalid-feedback d-block">
                                {errors.odometer_reading}
                              </div>
                            ) : null
                          }
                        </FormGroup>
                        {this.renderCheckList()}
                      </CardBody>
                    </Card>
                    <Card className="mt-4">
                      <CardBody className="pt-0">
                        <ProgressButton
                          type={'submit'}
                          color={'secondary'}
                          className={'d-flex justify-content-end'}
                          isLoading={this.state.isLoading}>
                          <Box pl={2} pr={2}>Submit</Box>
                        </ProgressButton>
                      </CardBody>
                    </Card>
                  </Container>
                </Col>
              </Form>
            )}
          </Formik>
        </Row>
      </Box>
    );
  }
}

export default VehicleCheclistForm;