import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom'

import { AuthContext } from "../../components/Auth/AuthDataProvider";
import apiUtil from '../../api/apiUtil';
import authApi from "../../api/Authentication";
import PageLayout from './PageLayout';

const RouteLayout = ({ component: Component, layout: Layout, ...rest }) => {
  const { authenticated, refreshToken, token, setToken, setAccessToken, setRefreshToken, setTokenType, clearStorage } = useContext(AuthContext)

  const checkToken = () => {
    let tokenObj = apiUtil.getObject(token);
    if(tokenObj && refreshToken){
      var created = new Date(tokenObj.created_at);
      var expired = new Date(created);
      expired.setSeconds(expired.getSeconds() + tokenObj.expires_in);

      if (expired < new Date()) {
        callRefreshTokenApi(refreshToken);
      }
    } else {
      clearStorage();
      return;
    }
  }

  const callRefreshTokenApi = (refreshToken) => {
    authApi.refreshToken(refreshToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          data.created_at = data.created_at * 1000;
          
          setToken(JSON.stringify(data));
          setAccessToken(data.access_token);
          setRefreshToken(data.refresh_token);
          setTokenType(data.token_type);
        }, (error, type) => {
          clearStorage();
          return;
        });
    });
  }


  React.useEffect(() => {
    checkToken();
  });


  Layout = Layout || PageLayout;

  if(authenticated){
    let scope = localStorage.getItem("scope");

    return (
      <Route {...rest} render={matchProps => (
          <Layout isFluid={rest.isFluid}>
            <Component {...matchProps} isFluid={rest.isFluid} />
          </Layout>
      )} />
    )
  } else {
    return (
      <Redirect to="/sign-in" />
    )
  }
};

export default RouteLayout;