import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import CustomerList from "./list";
import CustomerForm from "./form";

const Customers = ({ match }) => {
  let scope = localStorage.getItem("scope");

  if (scope == 'worker') {
    return (
      <Redirect to="/transaction/picking-order" />
      )
  } else if (scope == 'customer_admin') {
    return (
      <Redirect to="/transaction/sales-order" />
      )
  }

  return (
    <Switch>
      <Route
        path={`${match.url}/all-customers`}
        render={props => <CustomerList {...props} />}
      />
      <Route
        path={`${match.url}/create-customer`}
        render={props => <CustomerForm {...props} />}
      />
      <Route
        path={`${match.url}/edit-customer`}
        render={props => <CustomerForm {...props} />}
      />
      <Redirect to="/customers/all-customers" />
    </Switch>
  )
};

export default Customers;
