import React, { Component } from "react";

import {
  CircularProgress,
  Typography
} from '@material-ui/core';

import Button from '@material-ui/core/Button';

import {
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Label,
  Input,
  Col,
  FormGroup,
} from "reactstrap";

import balanceEnquiryApi from "../../../api/BalanceEnquiry";
import apiUtil from "../../../api/apiUtil";

class BatchEditLocationDialog extends Component {
  constructor(props) {
    super(props);

    let modal = ((props.modal === true || props.modal === false) ? props.modal : false);
    let items = props.items ? props.items : null;
    this.state = {
      modal: modal,
      isLoading: false,
      items: items,
      formData: {
        location: '',
      },
    };

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modal !== this.state.modal) {
      if (nextProps.modal) {
        let formState = this.state.formData;

        this.setState({
          formData: formState
        });
      }
      this.setState({
        modal: nextProps.modal
      });
    }

    if (nextProps.items !== this.state.items) {
      this.setState({
        items: nextProps.items
      })
    }
  }

  handleClose = () => {
    this.setState({
      modal: false
    }, () => {
      if (this.props.onClose) {
        this.props.onClose(true);
      }
    });
  }

  handleInputChange = event => {
    let formState = this.state.formData;
    formState[event.target.name] = event.target.value;

    this.setState({
      formData: formState
    });
  }

  handleSubmit = () => {
    let request = {
      //orderDetails: this.state.formData.location
      order_details: []
    }

    let items = this.state.items;

    if (items.length <= 0) {
      apiUtil.toast("Please select items.", 'error');
    } else {
      for (let i = 0; i < items.length; i++) {
        let orderDetail = {
          id: items[i].id,
          location: this.state.formData.location
        }

        request.order_details.push(orderDetail);
      }

      balanceEnquiryApi.batchUpdateLocation(request).then(data => {
        if (data.status === 200) {
          apiUtil.toast("Location updated.", 'success');
          this.setState({
            modal: false
          }, () => {
            if (this.props.onClose) {
              this.props.onClose(true);
            }
          });
        } else {
          apiUtil.toast(data.errorMessage, 'error');

          this.setState({
            modal: false
          }, () => {
            if (this.props.onClose) {
              this.props.onClose(true);
            }
          });
        }
      });
    }
  }

  render() {
    return (
      <Modal isOpen={this.state.modal} toggle={this.handleClose} size="md" zIndex={1300}>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <Label className="control-label">Location</Label>
                <Input name="location" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.location} />
                {/* <Select className="form-input no-border" options={locationData} onChange={this.handleLocationChange} /> */}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button
            className="btn-theme-1 form-input-btn"
            color="info"
            variant="contained" size="sm" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button
            className="btn-theme form-input-btn"
            color="primary"
            variant="contained" size="sm"
            onClick={this.handleSubmit}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default BatchEditLocationDialog;