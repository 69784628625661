import React, { Component } from "react";
import { Box } from '@material-ui/core';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Label,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import { Formik, Form } from "formik";
import * as Yup from "yup";
import queryString from 'query-string';
import Loading from "react-fullscreen-loading";

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";

import VehicleChecklist from './Partial/VehicleChecklist';

import apiUtil from "../../api/apiUtil";
import api from "../../api/api.js";

const VehicleSchema = Yup.object({

});

class VehicleForm extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      dataId: '',
      errors: {},
      isLoading: false,
      pageLoading: false,
      oldData: null,
      formData: {
        plate_no: '',
        manufacturer: '',
        model: '',
        colour: '',
        load_capacity: '',
        engine_capacity: '',
        registration_date: null,
        road_tax_expiry_date: null,
        inspection_date: null
      }
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.populateData = this.populateData.bind(this);
  }

  componentWillMount() {
    this._isMounted = true;

    let urlQuery = queryString.parse(window.location.search);
    if (urlQuery.id !== undefined) {
      this.setState({
        pageLoading: true,
        dataId: urlQuery.id
      });
      this.getData(urlQuery.id);
      document.title = "View Vehicle | WMS";
    } else {
      document.title = "Create View | WMS";
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getData = id => {
    api.details("vehicles", id).then((result) => {
      apiUtil.parseResult(
        result,
        (data) => {
          let result = data && data.result ? data.result : null;

          if (result) {
            this.populateData(result);
          } else {
            apiUtil.toast(data.errorMessage, 'warning');
          }
        },
        (error, type) => {
          apiUtil.toast(error.message, 'error');
        }
      );
    });
  }

  populateData = data => {
    let formData = this.state.formData;

    for (const key in formData) {
      if (key.includes('date') && data[key]) {
        formData[key] = new Date(data[key]);
      } else {
        formData[key] = data[key];
      }
    }

    this.setState({
      //oldData: data,
      pageLoading: false,
      formData: formData,
    });
  }

  handleInputChange = event => {
    let formState = this.state.formData;
    formState[event.target.name] = event.target.value;

    this.setState({
      formData: formState
    });
  }

  handleSelectChange = (event, name) => {
    let formState = this.state.formData;
    formState[name] = event.value;

    this.setState({
      formData: formState
    });
  }

  handleSubmit = values => {

  }

  vehicleChecklistDialog = () => {
    return <VehicleChecklist vehicleId={this.state.dataId} />
  }

  render() {
    return (
      <Box className="dashboard-page">
        <StickyPageHeader>
          <BreadCrumb />
          <h1 className="page-title">{this.state.dataId !== "" ? "Update Vehicle" : "Create Vehicle"}</h1>
        </StickyPageHeader>

        <Loading loading={this.state.pageLoading} background="#f5f5f5" loaderColor="#e89cae" />
        <Row>
          <Formik
            enableReinitialize={true}
            validationSchema={VehicleSchema}>
            {({

            }) => (
              <Form>
                <Col md={{ size: 12 }}>
                  <Container className={this.state.readOnly ? "overlay" : ""}>
                    <Card className="mt-3">
                      <CardBody className="pt-0">
                        <Row>
                          <Col xs="12">
                            <h4 className="font-weight-bold mb-4">Vehicle's information</h4>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            <div className="mb-4">
                              <Label className="control-label">
                                Plate Number
                              </Label>
                              <Input className="form-control has-margin form-input" defaultValue={this.state.formData.plate_no} disabled="" readOnly="readonly" />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            <div className="mb-4">
                              <Label className="control-label">
                                Manufacturer
                              </Label>
                              <Input className="form-control has-margin form-input" defaultValue={this.state.formData.manufacturer} disabled="" readOnly="readonly" />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            <div className="mb-4">
                              <Label className="control-label">
                                Model
                              </Label>
                              <Input className="form-control has-margin form-input" defaultValue={this.state.formData.model} disabled="" readOnly="readonly" />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            <div className="mb-4">
                              <Label className="control-label">
                                Vehicle Color
                              </Label>
                              <Input className="form-control has-margin form-input" defaultValue={this.state.formData.colour} disabled="" readOnly="readonly" />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            <div className="mb-4">
                              <Label className="control-label">
                                Load Capacity
                              </Label>
                              <Input className="form-control has-margin form-input" defaultValue={this.state.formData.load_capacity} disabled="" readOnly="readonly" />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            <div className="mb-4">
                              <Label className="control-label">
                                Engine Capacity
                              </Label>
                              <Input className="form-control has-margin form-input" defaultValue={this.state.formData.engine_capacity} disabled="" readOnly="readonly" />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            <div className="mb-4">
                              <Label className="control-label">
                                Registration Date
                              </Label>
                              <div className="form-input">
                                <DatePicker dateFormat="dd/MM/yyyy" className="form-control" selected={this.state.formData.registration_date} readOnly="readonly" />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            <div className="mb-4">
                              <Label className="control-label">
                                RoadTax Expiry Date
                              </Label>
                              <div className="form-input">
                                <DatePicker dateFormat="dd/MM/yyyy" className="form-control" selected={this.state.formData.road_tax_expiry_date} readOnly="readonly" />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            <div className="mb-4">
                              <Label className="control-label">
                                Inspection Date
                              </Label>
                              <div className="form-input">
                                <DatePicker dateFormat="dd/MM/yyyy" className="form-control" selected={this.state.formData.inspection_date} readOnly="readonly" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Container>
                </Col>
              </Form>
            )}
          </Formik>
        </Row>
        <Row className="pb-5 mb-5">
          <Col md={{ size: 12 }}>
            <Container>
              {this.vehicleChecklistDialog()}
            </Container>
          </Col>
        </Row>
      </Box>
    );
  }
}

export default VehicleForm;