import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
	Box,
	Tabs,
	Tab,
	Typography
} from '@material-ui/core';
import { Container, Col, Row } from "reactstrap";
import MUIDataTable from "mui-datatables";
import moment from 'moment';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

class HandlingReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			customer: this.props.reportData.customer,
			total: parseFloat(this.props.reportData.grand_total_charges),
			rateIn: this.props.reportData.rate_in,
			rateOut: this.props.reportData.rate_out,
			title: this.props.reportData.result.Title,
			reportIn: this.props.reportData.result.Handling_In,
			reportOut: this.props.reportData.result.Handling_Out,
			reportData: this.props.reportData,
			tab: 0,
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.reportData.customer.id !== this.state.customer.id) {
			this.setState({
				customer: nextProps.reportData.customer,
				total: parseFloat(nextProps.reportData.grand_total_charges),
				title: nextProps.reportData.result.Title,
				reportIn: nextProps.reportData.result.Handling_In,
				reportOut: nextProps.reportData.result.Handling_Out,
				reportData: nextProps.reportData
			});
		}
	}

	render() {
		const columns = [
			{
				name: "Ref_NO.",
				label: "Ref No."
			},
			// {
			//   name: "Document_No",
			//   label: "Document No"
			// },
			{
				name: "Date",
				label: "Date",
				options: {
					customBodyRender: (value) => {
						return value ? moment(value).format("DD-MM-YYYY") : '';
					}
				}
			},
			{
				name: "CR/PO/SO",
				label: "CR/PO/SO"
			},
			{
				name: "Remarks",
				label: "Remarks"
			},
			//   {
			//     name: "LineItems",
			//     label: "Line item"
			//   },
			//   {
			//     name: "Quantity",
			//     label: "Quantity"
			//   },
			{
				name: "Weight_(kg)",
				label: "Weight (kg)"
			},
			{
				name: "Total_(m3)",
				label: "Total (m3)",
				options: {
					customBodyRender: (value) => {
						return parseFloat(value).toFixed(6).replace(/\d(?=(\d{3})+\.)/g, '$&,');
					}
				}
			},
		];
		const handlingOutcolumns = [
			{
				name: "Ref_NO.",
				label: "Ref No."
			},
			{
			  name: "Document_No",
			  label: "Document No"
			},
			{
				name: "Date",
				label: "Date"
			},
			{
				name: "Address",
				label: "Delivery address"
			},
			{
				name: "Remarks",
				label: "Remarks"
			},
			{
				name: "OrderType",
				label: "Order Type"
			},
			{
				name: "LineItems",
				label: "Line item"
			},
			{
				name: "Quantity",
				label: "Quantity"
			},
		];

		const options = {
			selectableRows: 'none',
			rowsPerPage: 10,
			serverSide: false
		}

		const handleTabChange = (event, newValue) => {
			this.setState({
				tab: newValue
			});
		};

		return (
			<Container fluid={true}>
				<Row className="mt-3">
					<Col xs="12">
						<div className="text-center mt-3 mb-3">
							<h3>Grand Total</h3>
							<h2 className="font-weight-bold h2">{this.state.total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h2>
						</div>

						<Box sx={{ width: '100%' }}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<Tabs value={this.state.tab} onChange={handleTabChange} aria-label="basic tabs example">
									<Tab label="Handling In" {...a11yProps(0)} />
									<Tab label="Handling Out" {...a11yProps(1)} />
								</Tabs>
							</Box>
							<TabPanel className="pt-3 pb-5 px-4" value={this.state.tab} index={0}>
								<h2 className="mb-3">{this.state.title}</h2>
								<div style={{ display: 'block' }}>
									<div>Total Incoming Jobs: <span className="font-weight-bold">{this.state.reportIn.length}</span></div>
									<div>Incoming Rate: <span className="font-weight-bold">{this.state.rateIn}</span></div>
									<div className="mb-3">Total Charges: <span className="font-weight-bold">{parseFloat(this.state.reportData.total_handling_in).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span></div>
								</div>

								<MUIDataTable
									title={
										<Typography variant="h6">
											Handling In
										</Typography>
									}
									data={this.state.reportIn}
									columns={columns}
									options={options}
								/>
							</TabPanel>
							<TabPanel className="pt-3 pb-5 px-4" value={this.state.tab} index={1}>
								<h2 className="mb-3">{this.state.title}</h2>
								<div style={{ display: 'block' }}>
									<div>Total for e-commerce job: <span className="font-weight-bold">{this.state.reportData.ecommerce.total_out}</span></div>
									<div>Total for non e-commerce job: <span className="font-weight-bold">{this.state.reportData.non_ecommerce.total_out}</span></div>
									<div>Total Outgoing jobs: <span className="font-weight-bold">{this.state.reportOut.length}</span></div>
									<div>Outgoing rate: <span className="font-weight-bold">{this.state.rateOut}</span></div>
									<div>Total Charges: <span className="font-weight-bold">{parseFloat(this.state.reportData.total_handling_out).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span></div>
								</div>
								<MUIDataTable
									title={
										<Typography variant="h6">
											Handling Out
										</Typography>
									}
									className="mt-3"
									data={this.state.reportOut}
									columns={handlingOutcolumns}
									options={options}
								/>
							</TabPanel>
						</Box>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default HandlingReport;