import axios from 'axios';

const {
  REACT_APP_WMS_API_URL
} = process.env;

const salesOrderApi = {
  baseApiUrl: () => {
    return REACT_APP_WMS_API_URL;
  },

  get: async (params) => {
    let errorMessage = "";
    let total = 0;
    params.handling_in = 'true';

    if (params.optional_filter_by && params.optional_filter_by != "query") {
      params[params.optional_filter_by] = params.optional_query;
      params.optional_filter_by = null;
      params.optional_query = null;
    }

    const URL = REACT_APP_WMS_API_URL + "admin/api/orders";

    let access_token = localStorage.getItem("access_token");
    let result = [];

    await axios.get(
      URL,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
      .then(res => {
        if (res.status === 200 && res.data.result.length > 0) {
          total = res.data.total;
          for (let i = 0; i < res.data.result.length; i++) {
            result[i] = res.data.result[i];
            if (res.data.result[i].end_user) {
              result[i]['end_user_name'] = res.data.result[i].end_user.end_user;
            }
          }
        }
      }).catch(error => {
        errorMessage = error.message;
      });

    return { data: result, total: total, errorMessage: errorMessage };
  },

  download: async (params) => {
    let errorMessage = "";
    let total = 0;
    params.handling_in = 'true';
    const URL = REACT_APP_WMS_API_URL + "admin/api/orders";

    let access_token = localStorage.getItem("access_token");
    let result = [];

    await axios.get(
      URL,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
      .then(res => {
        if (res.status === 200 && res.data.result.length > 0) {
          total = res.data.total;
          for (let i = 0; i < res.data.result.length; i++) {
            result[i] = res.data.result[i];
            if (res.data.result[i].end_user) {
              result[i]['end_user_name'] = res.data.result[i].end_user.end_user;
            }
          }
        }
      }).catch(error => {
        errorMessage = error.message;
      });

    return { data: result, total: total, errorMessage: errorMessage };
  },

  show: async (id) => {
    let errorMessage = "";
    let status = 200;

    const URL = REACT_APP_WMS_API_URL + "admin/api/orders/" + id;

    let access_token = localStorage.getItem("access_token");

    const getResult = await axios.get(
      URL,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
      .catch(error => {
        status = error.response.status;
        errorMessage = error.message;
      });

    return { data: getResult.data, errorMessage: errorMessage, status: status };
  },

  create: async (request) => {
    let errorMessage = "";
    let status = 200;

    const URL = REACT_APP_WMS_API_URL + "admin/api/orders";

    let access_token = localStorage.getItem("access_token");

    const result = await axios.post(
      URL,
      request,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
      .catch(error => {
        status = error.response.status;
        errorMessage = error.message;
      });
    return { data: result, errorMessage: errorMessage, status: status };
  },

  update: async (id, request) => {
    let errorMessage = "";
    let status = 200;

    const URL = REACT_APP_WMS_API_URL + "admin/api/orders/" + id;
    const access_token = localStorage.getItem("access_token");

    const result = await axios.put(
      URL,
      request,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${access_token}`
        }
      })
      .catch(error => {
        status = error.response.status;
        errorMessage = error.response && error.response.data && error.response.data.error ? error.response.data.error : error.message;
      });

    return { data: result, errorMessage: errorMessage, status: status };
  },

  delete: async (id) => {
    let errorMessage = "";
    let status = 0;

    const URL = REACT_APP_WMS_API_URL + "admin/api/orders/" + id;
    const access_token = localStorage.getItem("access_token");

    const result = await axios.delete(
      URL,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${access_token}`
        }
      })
      .catch(error => {
        status = error.response.status;
        errorMessage = error.message;
      });

    return { data: result.data, errorMessage: errorMessage, status: status };
  },

  getDetail: async (params) => {
    let errorMessage = "";
    let status = 200;

    const URL = REACT_APP_WMS_API_URL + "admin/api/order_details";
    let access_token = localStorage.getItem("access_token");

    const getResult = await axios.get(
      URL,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        },
        params: params
      })
      .catch(error => {
        status = error.response.status;
        errorMessage = error.message;
      });

    return { data: getResult.data, errorMessage: errorMessage, status: status };
  },

  getDetailItem: async (id, params) => {
    let errorMessage = "";
    let status = 200;

    const URL = REACT_APP_WMS_API_URL + "admin/api/order_details/" + id;
    let access_token = localStorage.getItem("access_token");

    const getResult = await axios.get(
      URL,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        },
        params: params
      })
      .catch(error => {
        status = error.response.status;
        errorMessage = error.response.data && error.response.data.error ? error.response.data.error : error.message;
      });

    return { data: errorMessage ? null : getResult.data, errorMessage: errorMessage, status: status };
  }
}

export default salesOrderApi;