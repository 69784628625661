import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import SelfCollectionAll from "./all";
import SelfCollectionList from "./list";

const BalanceEnquiry = ({ match }) => (
    <Switch>
      <Route
        path={`${match.url}/list`}
        render={props => <SelfCollectionList {...props} />}
      />
      <Route
        path={`${match.url}/all`}
        render={props => <SelfCollectionAll {...props} />}
      />
      <Redirect to="/transaction/balance-enquiry/list" />
    </Switch>
);

export default BalanceEnquiry;
